<template>
  <div class="upload-sin">
    <NavBack/>
    <div class="page-header sticky-header has-text-centered is-relative is-flex is-justify-content-center is-align-items-center">
      <div class="go-back" @click="goBack">
        <img src="@assets/icons/arrow-left.svg" alt="go-back">
      </div>
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toDashboard">
    </div>
    <div class="page-title has-text-weight-semibold has-text-centered">Submit your SIN</div>
    <div class="main-body">
      <div class="white-box has-background-white">
        <div class="text has-text-centered">Your SIN will be used to prepare your T5 investment income slips.</div>
        <div :class="`input-label has-text-weight-semibold ${requestsNoSubmission ? 'grey-out' : ''}`"> Input your SIN:</div>
        <div>
          <ValidationObserver v-slot="{ valid }" ref="observer">
            <ValidationProvider rules="required|valid-sin" v-slot="{ classes, errors, failed, passed }" name="SIN" vid="sin">
              <input
                :class="`input is-medium-height mt-1 ${classForField({passed, failed})}`"
                v-model.trim="sinNumber"
                placeholder="e.g. 123 123 123"
                @input="removeSpaces('sinNumber')"
                :disabled="requestsNoSubmission"
              >
              <p class="help is-danger">{{ errors[0] }}</p>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:sin" v-slot="{ classes, errors, failed, passed }" name="SIN">
              <div class="field mt-2">
                <div class="control has-icons-right">
                  <input
                    v-model.trim="sinNumberConfirmation"
                    placeholder="Confirm your SIN"
                    :class="`input is-medium-height ${classForField({passed, failed})}`"
                    @input="removeSpaces('sinNumberConfirmation')"
                    :disabled="requestsNoSubmission"
                  >
                  <span class="icon is-small is-right">
                    <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
                    <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
                  </span>
                </div>
                <p class="help is-danger" v-show="failed">{{confirmedSinErrorMsg(errors[0])}}</p>
              </div>
            </ValidationProvider>
            <div class="consent-check">
              <b-checkbox
                v-model="hasConsented"
                type="is-blue"
              >
                I consent to receive my tax documents electronically
              </b-checkbox>
            </div>
            <div class="no-sin-check">
              <b-checkbox
                v-model="requestsNoSubmission"
                type="is-blue"
                @input="resetFormNoSubmission"
              >
                I don't want to submit my SIN
              </b-checkbox>
            </div>
            <div class="has-text-centered">
              <button :class="`button submit-button ${submitButtonIsLoading ? 'is-loading' : ''}`" @click="doSinUpload" :disabled="submitButtonDisabled(valid)">
                <span class="has-text-weight-bold">{{dynamicButtonText}}</span>
              </button>
            </div>
          </ValidationObserver>
        </div>
        <div v-if="showErrorMessage" class="errors">{{errorMessage}}</div>
        <div class="has-text-centered encryption-hint">
          We know that this information is sensitive and we use enterprise encryption to ensure your data is secure.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import { uploadSIN, getProfileDetails as getTaxDocsAvailability } from '@api/profile'
import formMixins from '@utils/mixins/form-mixins.js'

export default {
  mixins: [formMixins],
  components: {
    NavBack,
  },
  data() {
    return {
      showErrorMessage: false,
      errorMessage: null,
      sinNumber: null,
      sinNumberConfirmation: null,
      requestsNoSubmission: false,
      hasConsented: true,
      submitButtonIsLoading: false,
      taxDocsAvailable: false,
    }
  },
  created() {
    getTaxDocsAvailability().then((result) => {
      if (!result.success) return
      this.taxDocsAvailable = result.data.taxDocsAvailable
    })
  },
  computed: {
    dynamicButtonText() {
      return this.requestsNoSubmission ? 'Continue without SIN' : 'Submit'
    },
  },
  methods: {
    confirmedSinErrorMsg(error) {
      return error === 'SIN is not valid.' ? 'Error: Your SINs don’t match 😦' : error
    },
    doSinUpload() {
      this.submitButtonIsLoading = true
      this.showErrorMessage = false
      uploadSIN({
        sin_number: this.requestsNoSubmission ? null : this.sinNumber,
        electronic_reception: this.hasConsented,
        no_submission: this.requestsNoSubmission,
      }).then(() => {
        this.$router.push(`/investorProfile/sinInput/success/${this.taxDocsAvailable ? 2 : 1}`)
      }).catch((error) => {
        this.submitButtonIsLoading = false
        this.errorMessage = `An error has occurred: ${error}`
        this.showErrorMessage = true
      })
    },
    submitButtonDisabled(valid) {
      return !this.requestsNoSubmission && !valid
    },
    resetFormNoSubmission() {
      this.requestsNoSubmission && this.$refs.observer.reset()
    },
    removeSpaces(value) {
      if (this[value]) {
        this[value] = this[value].replace(/\s/g, '')
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    toDashboard() {
      this.$router.push('/dashboard')
    }
  },
}
</script>
<style lang="scss" scoped>
.upload-sin {
  .page-header {
    height: 68px;
    .go-back {
      padding: 0 10px;
      position: absolute;
      top: 27px;
      left: 20px;
    }
    .logo {
      width: 63px;
    }
  }
  .page-title {
    font-size: 28px;
    line-height: 38px;
  }
  .main-body {
    padding: 12px 25px 0;
    .input-label {
      font-size: 16px;
      margin: 30px 0 6px 10px;
      &.grey-out {
        color: #8b8987;
      }
    }
    .b-checkbox.checkbox {
      margin-left: 10px;
      margin-right: unset;
      ::v-deep .check {
        width: 24px!important;
        height: 24px!important;
        border-color: #4A26AA!important;
      }
      ::v-deep .control-label {
        font-size: 16px;
        line-height: 19px;
        padding-left: 10px;
      }
    }
    .consent-check {
      margin-top: 30px;
    }
    .no-sin-check {
      margin-top: 22px;
    }
    .submit-button{
      background: #4A26AA;
      width: 100%;
      height: 43px;
      border-radius: 12px;
      margin-top: 20px;
      span{
        color: #fff;
        font-weight: 501;
      }
    }
    .invalid{
      border-color: #f00 !important;
    }
    .checkbox{
      font-size: 1rem;
      margin: 0 0.9rem;
    }
    .white-box {
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      .box-title {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .text {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .upload {
      margin-top: 30px;
      > input {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .icon-background {
        width: 40px;
        height: 40px;
        background-color: #F0EEF8;
        border-radius: 50%;
        margin-right: 14px;
        img {
          width: 22px;
        }
      }
      .item {
        font-size: 20px;
      }
    }
    .encryption-hint {
      color: #9185AE;
      font-size: 12px;
      line-height: 14px;
      margin-top: 24px;
    }
  }
}
</style>

<template>
  <div :class="`modal px-2 ${isActive ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-content has-background-white has-text-centered">
      <div class="px-2 py-2 icon-close-padding is-clickable" @click="closeModal">
        <img src="@assets/icons/close.svg" alt="close" class="icon-close">
      </div>
      <div class="modal-header">Enter password to view documents</div>
      <div class="modal-text">Your tax documents contain your confidential information, like your SIN. To keep things secure, enter your password to access them 🔒</div>
      <ValidationObserver v-slot="{ valid }">
        <ValidationProvider rules="required" v-slot="{ failed, errors}" name="Password Confirmation">
          <input
            :class="`input ${wrongPassword || failed ? 'invalid' : ''}`"
            v-model.trim="passwordConfirmation"
            placeholder="Enter your password"
            type="password"
            @input="resetPasswordError">
          <div class="errors mt-1 has-text-left"> {{ wrongPassword ? "Uh oh, that's not the right password!" : errors[0] }} </div>
          <div>
            <b-button
              type="has-background-blue button-ok"
              class="has-text-weight-bold"
              @click="doConfirm"
              :disabled="!valid"
              :loading="isLoading"
            >Access documents</b-button>
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { confirmPassword } from '@api/auth'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      passwordConfirmation: null,
      isLoading: false,
      wrongPassword: false,
    }
  },
  watch: {
    showModal: {
      handler(newValue) {
        this.isActive = newValue
      },
      immediate: true
    }
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
      this.passwordConfirmation = null
    },
    doConfirm() {
      this.isLoading = true
      confirmPassword({ password_confirmation: this.passwordConfirmation }).then((result) => {
        this.isLoading = false
        if (result.success) this.$emit('passwordConfirmed', result.data)
        else this.wrongPassword = true
      })
    },
    resetPasswordError() {
      if (this.wrongPassword) this.wrongPassword = false
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.modal {
  justify-content: flex-end;
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 48px 20px 24px;
    margin-bottom: 8px;

    .icon-close-padding {
      position: absolute;
      left: 4px;
      top: 14px;
      .icon-close {
        width: 24px;
      }
    }
    .modal-header {
      font-weight: 600;
      font-size: 20px;
      padding-bottom: 20px;
    }
    .modal-text {
      font-size: 16px;
      padding-bottom: 20px;
      line-height: 20px;
    }
    .button-ok {
      width: 100%;
      height: 51px;
      font-size: 16px;
      border-radius: 12px;
      margin-top: 34px;
    }

    input {
      height: 52px;
      &.invalid{
        border-color: #C91717;
        color: #C91717;
      }
    }

    .errors {
      height: 20px;
      color: #C91717;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .modal {
    justify-content: center;
  }
}
</style>

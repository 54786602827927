<template>
  <div class="tax-documents">
    <NavBack/>
    <div class="page-header sticky-header">
      <MenuAndLogo :showReferral="true" referralIconBackgroundColor="#fff"></MenuAndLogo>
    </div>
    <div class="header-title has-text-centered has-text-weight-semibold">Tax Documents</div>
    <div class="main-content">
      <div class="mt-4 mb-4" v-if="!sinIsPresent">
        <div class='heading'>Submit your SIN</div>
        <div class="subheading has-text-centered px-5 is-flex is-align-items-center">Your SIN will be used to prepare your T5 investment income slips.</div>
        <div class="sin-input is-flex is-justify-content-space-between is-align-items-center is-clickable" @click="toSinInput">
          <div class="item">Input your SIN</div>
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
      </div>
      <ul class="mt-5" v-if="receiveTaxDocsElectronically">
        <li v-for="(year, index) in taxDocs" :key="year.year">
          <div class="heading">{{ year.year }}</div>
          <ul class="row mb-5">
            <div
              v-for="(property, i) in year.properties"
              :key="property.id"
              @click="showLoading(index, i)"
            >
              <div
                class="sin-input is-flex is-justify-content-space-between is-align-items-center is-clickable"
                @click="taxDocDownload(property.id, year.year)"
              >
                <div class="item">{{ property.docType }} - {{ property.name }}</div>
                <button class="button is-loading" v-if="property.loading"></button>
                <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4" v-else>
              </div>
            </div>
          </ul>
        </li>
      </ul>
    </div>
    <div class="mt-6 has-text-centered switch-container">
      <b-switch
        size="is-medium"
        type="is-blue"
        v-model="receiveTaxDocsElectronically"
        left-label
        @input="toggleReception"
      >
        Receive tax documents electronically
      </b-switch>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import { getProfileDetails, toggleElectronicReception } from '@api/profile'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  data() {
    return {
      receiveTaxDocsElectronically: false,
      sinIsPresent: true,
    }
  },
  computed: {
    hasSubmittedSin() {
      return this.sinSubmitted
    },
    taxDocs() {
      const saved = localStorage.getItem('taxDocs')
      return saved ? JSON.parse(saved) : []
    },
  },
  created() {
    this.init()
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/investorProfile/sinInput/upload') localStorage.removeItem('taxDocs')
    next()
  },
  components: {
    NavBack,
    MenuAndLogo,
  },
  methods: {
    init() {
      getProfileDetails().then((result) => {
        if (!result.success) return

        const { electronicReception, hasUploadedSin } = result.data
        this.receiveTaxDocsElectronically = electronicReception
        this.sinIsPresent = hasUploadedSin
      })
    },
    toSinInput() {
      this.$router.push('/investorProfile/sinInput')
    },
    toggleReception() {
      toggleElectronicReception({ electronic_reception: this.receiveTaxDocsElectronically }).then((response) => {
        if (!response.success) this.receiveTaxDocsElectronically = !this.receiveTaxDocsElectronically
      }).catch((e) => {
        this.receiveTaxDocsElectronically = !this.receiveTaxDocsElectronically
      })
    },
    taxDocDownload(propertyId, year) {
      const prefix = window.location.hostname === 'localhost' ? '' : getImbyDomain()
      const link = `${prefix}/api/v1/investors/profile/tax_slip_download?property_id=${propertyId}&year=${year}`
      if (embeddedInIos()) {
        const value = JSON.stringify({ link: link, filename: `${year}_${propertyId}_T5.pdf` })
        window.webkit.messageHandlers.downloadFileUrl.postMessage(value)
      } else window.location.href = link
    },
    showLoading(index, i) {
      if (this.taxDocs[index].properties[i].loading) return
      this.taxDocs[index].properties[i].loading = true
      this.$forceUpdate()
      setTimeout(() => {
        this.taxDocs[index].properties[i].loading = false
        this.$forceUpdate()
      }, 3000)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.tax-documents {
  .switch-container {
    ::v-deep .switch input[type="checkbox"] + .check{
      box-shadow: none;
      width: 56px;
      height: 32px;
      &::before {
        width: 24px;
        height: 24px;
      }
    }
    ::v-deep .switch input[type="checkbox"]:not(:checked) + .check{
      background-color: #D1C9E4;
    }

    ::v-deep .switch.is-medium{
      font-size: 16px;
      width: 100%;
      justify-content: space-between;
      padding: 0 24px 0 30px;
      margin-right: 0;
    }
  }

  &.one-page {
    height: 100vh;
    overflow: hidden;
  }
  .page-header {
    background-color: #F0EEF8;
  }
  .header-title {
    font-size: 28px;
    background-color: #F0EEF8;
    padding: 10px 0 20px;
  }
  .main-content {
    padding: 0 24px;
  }
  .heading{
    color: #4A26AA;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 0;
  }
  .subheading {
    height: 66px;
    font-size:14px;
    line-height: 17px;
    margin: 30px 0 16px;
    background:#fff;
    color: #9185AE;
    box-shadow: 0px 32px 40px rgba(215, 145, 51, 0.08);
    border-radius: 12px;
  }
  .sin-input{
    padding: 22px 0;
    border-bottom: 1px solid #ECECEC;
    font-size: 16px;
    &:hover {
      background-color: #f6f5fc86;
    }
    .item{
      margin-left: 6px;
    }
    .button.is-loading {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      height: 15px;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .tax-documents {
    min-height: $min-height-mobile-container;
    padding-bottom: 60px;
    .header-title {
      padding-top: 40px;
      background-color: transparent;
    }
  }
}
</style>

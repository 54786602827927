<template>
  <div class="sin-success-wrapper is-clearfix">
    <div class="page-header sticky-header has-text-centered is-relative is-flex is-justify-content-center is-align-items-center">
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toDashboard">
    </div>
    <div class="page-title has-text-weight-semibold has-text-centered">Submit your SIN</div>
    <div class="main-body is-clearfix">
      <div class="white-box has-background-white has-text-centered">
        <div class="box-title has-text-weight-semibold">Thanks for updating your profile! 🙏</div>
        <img src="@assets/images/profile/t5_success.svg" alt="success" class="success-background">
        <div class="text">If eligible, your new tax documents will be ready to access by each tax season 😊 </div>
        <div class="text" v-if="!taxDocsAvailable">Please check back later!</div>
        <button class="button is-blue view-doc-button" @click="finishFlow" v-if="taxDocsAvailable">
          <span class="has-text-weight-bold">View tax documents</span>
        </button>
      </div>
    </div>
    <button class="button finish-button is-blue is-rounded is-pulled-right" @click="finishFlow" v-if="!taxDocsAvailable">
      <span class="has-text-weight-bold">Finish</span>
    </button>
    <ConfirmPasswordModal
      :showModal="showPasswordConfirmModal"
      @closeModal="showPasswordConfirmModal = false"
      @passwordConfirmed="passwordConfirmed"
    ></ConfirmPasswordModal>
  </div>
</template>
<script>
import ConfirmPasswordModal from '@components/modal/confirm-password-modal.vue'

export default {
  data() {
    return {
      showPasswordConfirmModal: false,
    }
  },
  computed: {
    taxDocsAvailable() {
      return this.$route.params.docsAvailable === '2' && this.$store.state.flipperToggles.t5Generation
    },
  },
  components: {
    ConfirmPasswordModal
  },
  methods: {
    passwordConfirmed(taxDocs) {
      localStorage.setItem('taxDocs', JSON.stringify(taxDocs))

      this.showPasswordConfirmModal = false
      this.$router.push('/investorProfile/taxDocs')
    },
    finishFlow() {
      if (this.taxDocsAvailable) {
        this.showPasswordConfirmModal = true
      } else {
        this.$router.push('/investorProfile')
      }
    },
    toDashboard() {
      this.$router.push('/dashboard')
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.page-header {
    height: 68px;
    .go-back {
      padding: 0 10px;
      position: absolute;
      top: 23px;
      left: 20px;
    }
    .logo {
      width: 63px;
    }
  }
  .page-title {
    font-size: 28px;
    line-height: 38px;
  }
  .main-body {
    padding: 12px 25px 0;
  }

  .white-box {
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);

      .success-background{
        width:180px;
        margin: 40px 0;
      }
      .box-title {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .text {
        font-size: 16px;
        line-height: 19px;
      }
      .view-doc-button {
        width: 100%;
        height: 42px;
        border-radius: 12px;
        margin-top: 40px;
        span{
          color: #fff;
          font-weight: 501;
        }
      }
    }
  .finish-button {
    width: 108px;
    height: 51px;
    position: fixed;
    bottom: 16px;
    right: 30px;
  }

@media only screen and (min-width: $min-viewport-width) {
  .finish-button {
    position: unset;
    bottom: unset;
    right: unset;
    margin-top: 30px;
    margin-right: 20px;
  }
}
</style>
